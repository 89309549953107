import Vue from 'vue'
import App from './App.vue'
import router from './router'

//  =======  导入全局变量 ==========
import global from '@/global/global.js'

// =======  ElementUI ==========
import { Msg } from './plugins/message.js'
import './plugins/element.js'
import './assets/css/global.css' // 导入全局样式表
import './assets/fonts/iconfont.css' // 导入字体图标

// 导入第三方表格插件  注册第三方组件: https://github.com/MisterTaki/vue-table-with-tree-grid/blob/master/example/Example.vue
import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table', TreeTable)

// 引入并使用 mavonEditor 文档: https://github.com/hinesboy/mavonEditor
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor) // 注册编辑器组件 全局

// =======  导入 NProgress 包对应的 JS 和 CSS ==========
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// ======= 全局导入自定义组件 =======
import BlogMenu from '@/cpts/blog-menu/blog-menu.vue'
Vue.component(BlogMenu.name, BlogMenu)

import FavBtn from '@/cpts/fav-btn/fav-btn.vue'
Vue.component(FavBtn.name, FavBtn)

// ===================  挂载请求组件 ======================
import axios from 'axios'

// ========================== 博客服务请求 ====================================================================
const httpBlog = axios.create({
  timeout: 10000 * 12,
  baseURL: global.baseBlogApiUrl,
})

// 在 request 拦截器中
httpBlog.interceptors.request.use(
  (config) => {
    NProgress.start() // 展示进度条 NProgress.start()
    config.headers.Authorization = window.localStorage.getItem('blog_token')
    global.picUploadHeaderObj.Authorization = window.localStorage.getItem('blog_token') // 为图片上传 加入token
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 在 response 拦截器中
httpBlog.interceptors.response.use(
  (response) => {
    NProgress.done() // 响应处理 隐藏进度条 NProgress.done()
    const msgObj = {
      message: '提示:' + response.data.message,
      duration: 3000,
      showClose: true,
      type: 'error',
    }

    // 未登录或者token失效
    if (response.data.code === 2) {
      Msg(msgObj)
      // 跳转到登录页面
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath },
      })
    } else if (response.data.code === 1) {
      // 请求接口报错 全局消息提示 省去 每个页面都写一次
      Msg(msgObj)
    }

    return response.data
  },
  (config) => {
    return config
  }
)


// ========================== 图片仓库请求 ====================================================================
const httpPicRepo = axios.create({
  timeout: 10000 * 12,
  baseURL: global.basePicRepoApiUrl,
})

// 在 request 拦截器中
httpPicRepo.interceptors.request.use(
  (config) => {
    NProgress.start() // 展示进度条 NProgress.start()
    // config.headers.Authorization = window.localStorage.getItem('blog_token')
    // global.picUploadHeaderObj.Authorization = window.localStorage.getItem('blog_token') // 为图片上传 加入token
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 在 response 拦截器中
httpPicRepo.interceptors.response.use(
  (response) => {
    NProgress.done() // 响应处理 隐藏进度条 NProgress.done()
    const msgObj = {
      message: '提示:' + response.data.message,
      duration: 3000,
      showClose: true,
      type: 'error',
    }

    // 未登录或者token失效
    if (response.data.code === 2) {
      Msg(msgObj)
      // 跳转到登录页面
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath },
      })
    } else if (response.data.code === 1) {
      // 请求接口报错 全局消息提示 省去 每个页面都写一次
      Msg(msgObj)
    }

    return response.data
  },
  (config) => {
    return config
  }
)


// 全局请求组件 调用方式this.$http.xxxx
Vue.prototype.$http = httpBlog
Vue.prototype.$httpPicRepo = httpPicRepo
// 全局变量 调用方式this.$global.xxxx
Vue.prototype.$global = global

Vue.config.productionTip = false

// 导入全局过滤器
import { dateFormat, capitalize } from '@/global/filter.js'
Vue.filter('dateFormat', dateFormat)
Vue.filter('capitalize', capitalize)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')