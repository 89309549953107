<template>
  <div class="blog_list_root">
    <blog-menu class="blog_menu_wrap" @menuItemClick="blogCatClick" :currentNodeKey="blogCatId"></blog-menu>

    <!-- 右侧内容主体 -->
    <div class="blog_list_wrap">
      <!-- 显示列表 -->
      <div v-if="blogList.length > 0" class="blog_list">
        <div class="blog_list_card" v-for="blog in blogList" :key="blog.id" @click="blogDetail(blog)">
          <div class="title">{{ blog.title }}</div>
          <div class="content">{{ blog.content }}</div>
          <div class="bottom">
            <div class="item label">原创</div>
            <div class="item">{{ blog.add_time | dateFormat }}</div>
            <div class="item"><i class="el-icon-view view_icon"></i>{{ blog.view }}</div>
          </div>
        </div>
      </div>

      <!-- 显示为空 -->
      <div v-else class="blog_list_empty">
        <div>该分类下没有博客哟!</div>
        <div class="btn" @click="blogWrite">开始写博客吧...</div>
      </div>
      <!-- 回到顶部 -->
      <el-backtop target=".blog_list_wrap" :visibility-height="166" :bottom="100" :right="60"> </el-backtop>
    </div>

  </div>
</template>

<script>
export default {
  props: ['params'],
  watch: {
    // 接收父组件的值
    params: function (val) {
      this.blogSearchContent = val
      this.getBlogList()
    },
  },
  data() {
    return {
      PAGE: {
        name: '博客',
        API: {
          list: 'blog/list',
          view: 'blog/view_count',
        },
      },

      // 博客列表
      blogList: [],
      blogCatId: 0,
      blogSearchContent: '',
    }
  },

  created() {
    this.blogCatId = window.localStorage.getItem('blogCatSelectedId') * 1
    this.getBlogList()
  },

  methods: {
    blogCatClick(item) {
      this.blogCatId = item.id
      this.getBlogList()
    },
    async getBlogList() {
      const res = await this.$http.get(this.PAGE.API.list, {
        params: { page: 1, page_size: 66, cat_id: this.blogCatId, query: this.blogSearchContent },
      })

      if (res.code !== 0) return
      this.blogList = res.data.list
    },
    async blogDetail(item) {
      const res = await this.$http.get(this.PAGE.API.view, { params: { id: item.id } })
      if (res.code !== 0) return

      window.localStorage.setItem('blogCatSelectedId', item.cat_id)
      this.$router.push({
        name: 'BlogDetail',
        query: { id: item.id },
      })
    },
    blogWrite() {
      this.$router.push({
        name: 'BlogWrite',
        query: {
          // cat_id: this.blogCatId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.blog_list_root {
  @include flex-row;
  height: 100%;

  .blog_list_wrap {
    flex: 1;
    @include scroll-x-hidden-y-auto;

    .blog_list {
      padding: 0px 6px;

      .blog_list_card {
        @include bg-glass;
        cursor: pointer;
        padding: 12px;
        border-bottom: 1px solid #eee;

        &:hover {
          background: rgba(255, 255, 255, .3);
        }

        .title {
          font-size: 16px;
          font-weight: bolder;
        }

        .content {
          font-size: 12px;
          padding: 12px 0px;
        }

        .bottom {
          @include flex-row-align-center;
          font-size: 12px;

          .item {
            @include flex-row-align-center;
            margin-right: 12px;

            .view_icon {
              margin-right: 6px;
              font-size: 13px;
            }
          }

          .label {
            @include flex-row;
            padding: 3px;
            color: red;
            border-radius: 6px;
            font-size: 12px;
            -webkit-transform: scale(0.9);
            background-color: #fcebea;
          }
        }
      }
    }

    .blog_list_empty {
      @include flex-row-center;
      @include bg-glass;
      font-size: 13px;
      padding: 12px;
      margin: 6px;

      .btn {
        color: blue;
        padding: 12px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }

  }
}
</style>
