<template>
  <div class="words_root">
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 菜单搜索区域 -->
      <el-row :gutter="6">
        <el-col :span="4">
          <el-button type="primary" size="small" @click="dialogFormDataShow()">添加{{ PAGE.name }}</el-button>
        </el-col>
      </el-row>

      <!-- 表格区域区域 -->
      <el-table :data="tableDataList" style="width: 100%" border stripe>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="content" label="内容"></el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.show === 10">显示</el-tag>
            <el-tag type="danger" v-else-if="scope.row.show === 20">隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="dialogFormDataShow(scope.row)">编辑 </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="tableRowDel(scope.row.id)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataTotal"
      >
      </el-pagination>
    </el-card>

    <!-- 添加对话框 -->
    <el-dialog :title="'添加' + PAGE.name" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false" @close="dialogClosed">
      <span>
        <el-form :model="formData" :rules="formDataRules" ref="formDataRef">
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" v-model="formData.content" rows="6"></el-input>
          </el-form-item>
          <el-form-item label="显示" prop="show">
            <el-switch v-model="formData.show" :active-value="10" :inactive-value="20"> </el-switch>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="dialogFormDataDo()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ========================== 页面信息 相关 ==========================
      PAGE: {
        name: '每日一句',
        API: {
          list: 'words/list',
          add: 'words/add',
          mod: 'words/mod',
          del: 'words/del',
        },
      },

      // ========================== 表格 分页相关 ==========================
      tableDataList: [],
      tableDataTotal: 0,
      queryInfo: {
        page: 1,
        page_size: 10,
      },

      // ========================== 表单 定义 ==========================
      isModPage: false,
      dialogVisible: false,
      formData: {
        show: 20,
      },
      formDataRules: {
        content: [
          { required: true, message: '内容', trigger: 'blur' },
          {
            min: 1,
            max: 128,
            message: '长度在 1 到 128 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.getTableDataList()
  },
  methods: {
    // ==================================== 获取列表系列 ====================================
    async getTableDataList() {
      const res = await this.$http.get(this.PAGE.API.list, { params: this.queryInfo })
      if (res.code !== 0) return
      if (!res.data.list) return this.$message.error('数据为空')

      this.tableDataList = res.data.list
      this.tableDataTotal = res.data.total
    },

    // 监听 size 改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.size = newSize
      this.getTableDataList()
    },
    // 监听页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getTableDataList()
    },

    // ==================================== 添加表单 ======================================
    dialogFormDataShow(item) {
      this.dialogVisible = true
      if (item) {
        this.isModPage = true
        this.formData = item
      } else {
        this.isModPage = false
        this.formData = {
          show: 10,
        }
      }
    },

    dialogFormDataDo() {
      this.$refs.formDataRef.validate(async (valid) => {
        if (!valid) return

        let action_url = this.PAGE.API.add
        if (this.isModPage) {
          action_url = this.PAGE.API.mod
        }

        const res = await this.$http.post(action_url, this.formData)
        if (res.code !== 0) return

        this.getTableDataList()

        this.dialogVisible = false
        this.$message.success('操作成功')
      })
    },

    // 监听添加菜单对话框的关闭条件
    dialogClosed() {
      this.$refs.formDataRef.resetFields()
    },

    // ===================================== 删除操作 方法 ====================================
    tableRowDel(itemId) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.get(this.PAGE.API.del, {
            params: { id: itemId },
          })

          if (res.code !== 0) return

          this.getTableDataList()
          return this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.words_root {
  @include scroll-x-hidden-y-auto;
}
</style>
