<template>
  <div class="blog_detail_root">
    <blog-menu @menuItemClick="blogCatClick" :currentNodeKey="selectedNodeKey"></blog-menu>

    <!-- 右侧内容主体 -->
    <div class="blog_detail_wrap">
      <div class="blog_detail">
        <!-- 标题 -->
        <div class="title_wrap">
          <div class="title">{{ blog.title }}</div>
          <div class="btn_wrap">
            <el-button type="text" @click="blogDel(blog.id)">删除</el-button>
            <el-button type="text" @click="blogMod(blog)">修改</el-button>
          </div>
        </div>
        <!-- 编辑器 -->
        <mavon-editor class="md" fontSize="13px" codeStyle="androidstudio" :value="blog.content" :subfield="false"
          :navigation="false" :defaultOpen="'preview'" :toolbarsFlag="false" :editable="false" :scrollStyle="true"
          previewBackground="rgb(199,237,204)">
        </mavon-editor>
      </div>

      <!-- 浮动菜单按钮 -->
      <fav-btn class="fav_fix_top" target=".blog_detail_wrap" :visibility-height="100" :bottom="160"
        @click="blogMod(blog)">
        <el-tooltip effect="dark" content="修改-快捷方式" placement="left-start">
          <i class="fav_icon el-icon-edit"></i>
        </el-tooltip>
      </fav-btn>

      <!-- 回到顶部 -->
      <el-backtop class="fav_fix_top" target=".blog_detail_wrap" :visibility-height="100" :bottom="100"></el-backtop>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      PAGE: {
        API: {
          info: 'blog/info',
          del: 'blog/del',
        },
      },
      selectedNodeKey: 0,
      // 博客
      blog: {},
    }
  },

  created() {
    this.selectedNodeKey = window.localStorage.getItem('blogCatSelectedId') * 1
    this.getBlogDetail(this.$route.query.id)
  },

  methods: {
    blogCatClick(item) {
      this.selectedNodeKey = item.id
      this.$router.push({
        name: 'BlogList', // params传参：只能用 name
        query: {
          // cat_id: item.id,
        },
      })
    },

    async getBlogDetail(id) {
      const res = await this.$http.get(this.PAGE.API.info, { params: { id: id } })

      if (res.code !== 0) return

      this.blog = res.data
      document.title = this.blog.title
    },

    blogMod(item) {
      this.$router.push({
        name: 'BlogWrite',
        query: { id: item.id },
      })
    },

    blogDel(itemId) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.get(this.PAGE.API.del, { params: { id: itemId } })

          if (res.code !== 0) return
          this.$router.push({ name: 'BlogList' })

          return this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.blog_detail_root {
  @include flex-row;
  height: 100%;

  .blog_menu_wrap {
    flex-shrink: 0;
  }

  .blog_detail_wrap {
    flex: 1;
    @include scroll-x-hidden-y-auto;

    .blog_detail {
      padding: 0px 6px;

      .title_wrap {
        @include flex-row-align-center;
        @include bg-glass;

        justify-content: space-between;
        padding: 6px 20px;
        border-bottom: 1px solid #eee;

        .title {
          font-weight: bolder;
          font-size: 26px;
        }

        .btn_wrap {
          flex-shrink: 0;

          .el-button {
            color: #eee;
          }
        }
      }

      .markdown-body {
        font-size: 13px;
      }
    }

  }
}
</style>
