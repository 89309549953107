<template>
  <div class="blog_cat_root">
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 菜单搜索区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" size="small" @click="dialogFormShow()">添加博客{{ PAGE.name }}</el-button>
        </el-col>
      </el-row>

      <!-- 可折叠表单 -->
      <el-table :data="menuTreeList" style="width: 100%; margin-bottom: 20px" row-key="id" border
        :default-expand-all="expandTableFlag" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column prop="name" label="菜单名"> </el-table-column>
        <el-table-column prop="sort" label="排序" sortable width="100">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.sort" placeholder="请输入数字"
              @change="tableRowSortChange(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="show" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.show === 10">显示</el-tag>
            <el-tag type="danger" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="dialogFormShow(scope.row)">编辑 </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="tableRowDel(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加/修改 对话框 -->
    <el-dialog :title="(formData.id ? '修改' : '添加') + PAGE.name" :visible.sync="dialogVisible" width="50%"
      :close-on-click-modal="false" @close="dialogClosed">
      <span>
        <el-form :model="formData" :rules="formRules" ref="formRef" label-width="80px">
          <el-form-item label="上级分类" prop="pid">
            <el-select v-model="formData.pid" placeholder="请选择">
              <el-option v-for="item in menuSelectList" :key="item.id" :label="item.indent + item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="显示" prop="show">
            <el-switch v-model="formData.show" active-color="#13ce66" inactive-color="#ff4949" :active-value="10"
              :inactive-value="20"> </el-switch>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model.number="formData.sort"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" @click="dialogFormDo" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      // ========================== 页面信息 相关 ==========================
      PAGE: {
        name: '分类',
        API: {
          menuTreeList: 'blog_cat/tree',
          menuTreeIndentList: 'blog_cat/indent',
          add: 'blog_cat/add',
          info: 'blog_cat/info',
          mod: 'blog_cat/mod',
          del: 'blog_cat/del',
        },
      },

      //获取菜单列表的参数对象
      menuSelectList: [],
      menuTreeList: [],
      typeList: [
        { label: '菜单', value: 10 },
        { label: '功能', value: 20 },
      ],
      expandTableFlag: false,

      // =============== 添加/修改 表单 定义==============
      dialogVisible: false,
      formDataTmp: {},
      formData: {
        pid: 0,
        name: '',
        show: 10,
        sort: 0,
      },
      formRules: {
        pid: [{ required: true, message: '上级分类未选择', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入分类', trigger: 'blur' },
          {
            min: 1,
            max: 63,
            message: '长度在 1 到 63 个字符',
            trigger: 'blur',
          },
        ],
        sort: [
          { required: true, message: '必须填写', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.formDataTmp = _.cloneDeep(this.formData) // 拷贝一份
    this.getMenuTreeList()
  },
  methods: {
    // ========== 获取列表系列 ==============
    async getMenuTreeList() {
      const res = await this.$http.get(this.PAGE.API.menuTreeList)
      if (res.code !== 0) return
      this.menuTreeList = res.data.tree
    },

    async getMenuIndentList(id) {
      const res = await this.$http.get(this.PAGE.API.menuTreeIndentList, { params: { cur_menu_id: id } })
      if (res.code !== 0) return

      this.menuSelectList = res.data.indent || []
      this.menuSelectList.unshift({ name: '顶级分类', id: 0, indent: '' })
    },

    // ==================================== 添加/修改 表单 ======================================
    async dialogFormShow(item) {
      if (item) {
        // 修改动作
        const res = await this.$http.get(this.PAGE.API.info, { params: { id: item.id } })
        if (res.code !== 0) return
        this.formData = res.data
        this.getMenuIndentList(this.formData.id)
      } else {
        // 添加动作
        this.formData = this.formDataTmp
        this.getMenuIndentList(0)
      }

      this.dialogVisible = true
    },

    dialogFormDo() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return

        let action_url = this.PAGE.API.add
        if (this.formData.id) {
          action_url = this.PAGE.API.mod
        }

        const res = await this.$http.post(action_url, this.formData)
        if (res.code !== 0) return

        this.getMenuTreeList()

        this.dialogVisible = false
        this.$message.success('操作成功')
      })
    },

    // 监听添加菜单对话框的关闭条件
    dialogClosed() {
      this.$refs.formRef.resetFields()
    },

    // ===================================== 删除操作 方法 ====================================
    tableRowDel(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.get(this.PAGE.API.del, {
            params: { id: id },
          })
          if (res.code !== 0) return

          this.$message.success('删除成功')
          this.getMenuTreeList()
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },

    // ===================================== 菜单排序 方法 ====================================
    async tableRowSortChange(row) {
      //这里需要转一次 不然 post提交 不过去参数
      const tmpRow = row
      const res = await this.$http.post(this.PAGE.API.mod, tmpRow)
      if (res.code !== 0) return

      this.getMenuTreeList()
      this.$message.success('排序成功')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.blog_cat_root {
  @include scroll-x-hidden-y-auto;

  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
