<template>
  <div class="menu_root">
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 菜单搜索区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" size="small" @click="dialogFormAddShow()">添加{{ PAGE.name }}</el-button>
        </el-col>
      </el-row>

      <!-- 可折叠表单 -->
      <el-table :data="menuTreeList" style="width: 100%; margin-bottom: 20px" row-key="id" border
        :default-expand-all="expandTableFlag" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column prop="name" label="菜单名" width="180"> </el-table-column>
        <el-table-column prop="path" label="路径"> </el-table-column>
        <el-table-column prop="icon" label="图标"> </el-table-column>
        <el-table-column prop="sort" label="排序" sortable width="80">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.sort" placeholder="请输入数字"
              @change="tableRowSortChange(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="菜单展示" width="130">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.type === 10">菜单</el-tag>
            <el-tag type="danger" v-else>功能</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.show === 10">显示</el-tag>
            <el-tag type="danger" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="dialogFormEditShow(scope.row)">编辑
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="tableRowDel(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加菜单对话框 -->
    <el-dialog :title="'添加' + PAGE.name" :visible.sync="dialogAddVisible" :close-on-click-modal="false" width="50%"
      @close="dialogAddClosed">
      <span>
        <el-form :model="formAdd" :rules="formAddRules" ref="formAddRef" label-width="80px">
          <el-form-item label="上级菜单" prop="pid">
            <el-select v-model="formAdd.pid" placeholder="请选择">
              <el-option v-for="item in menuSelectList" :key="item.id" :label="item.indent + item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="formAdd.type" placeholder="请选择">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单名" prop="name">
            <el-input v-model="formAdd.name"></el-input>
          </el-form-item>
          <el-form-item label="路径" prop="path">
            <el-input v-model="formAdd.path"></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="icon">
            <el-input v-model="formAdd.icon"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="formAdd.sort"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="dialogFormAddDo()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改菜单对话框 -->
    <el-dialog :title="'修改' + PAGE.name" :visible.sync="dialogEditVisible" :close-on-click-modal="false" width="50%"
      @close="dialogEditClosed">
      <span>
        <el-form :model="formEdit" :rules="formEditRules" ref="formEditRef" label-width="80px">
          <el-form-item label="上级菜单" prop="pid">
            <el-select v-model="formEdit.pid" placeholder="请选择">
              <el-option v-for="item in menuSelectList" :key="item.id" :label="item.indent + item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="type">
            <el-select v-model="formEdit.type" placeholder="请选择">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="菜单名" prop="name">
            <el-input v-model="formEdit.name"></el-input>
          </el-form-item>
          <el-form-item label="路径" prop="path">
            <el-input v-model="formEdit.path"></el-input>
          </el-form-item>
          <el-form-item label="图标" prop="icon">
            <el-input v-model="formEdit.icon"></el-input>
          </el-form-item>
          <el-form-item label="显示" prop="show">
            <el-switch v-model="formEdit.show" active-color="#13ce66" inactive-color="#ff4949" :active-value="10"
              :inactive-value="20"> </el-switch>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="formEdit.sort"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="dialogFormEditDo()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ========================== 页面信息 相关 ==========================
      PAGE: {
        name: '菜单',
        API: {
          menuTreeList: 'menu/tree',
          menuTreeIndentList: 'menu/indent',
          add: 'menu/add',
          mod: 'menu/mod',
          del: 'menu/del',
        },
      },
      //获取菜单列表的参数对象
      menuSelectList: [],
      menuTreeList: [],
      typeList: [
        { label: '菜单', value: 10 },
        { label: '功能', value: 20 },
      ],
      expandTableFlag: false,

      // =============== 添加表单 定义==============
      dialogAddVisible: false,
      formAdd: {
        pid: 0,
        name: '',
        path: '',
        type: 10,
        icon: 'el-icon-menu',
        sort: 0,
        status: 10,
      },
      formAddRules: {
        pid: [{ required: true, message: '上级菜单未选择', trigger: 'blur' }],
        type: [{ required: true, message: '菜单类型未选择', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入菜单名', trigger: 'blur' },
          {
            min: 1,
            max: 63,
            message: '长度在 1 到 63 个字符',
            trigger: 'blur',
          },
        ],
        path: [
          { required: true, message: '请输入路径', trigger: 'blur' },
          {
            min: 1,
            max: 128,
            message: '长度在 1 到 128 个字符',
            trigger: 'blur',
          },
        ],
        icon: [
          { required: true, message: '图标未填写', trigger: 'blur' },
          {
            min: 1,
            max: 128,
            message: '长度在 1 到 128 个字符',
            trigger: 'blur',
          },
        ],
        sort: [
          { required: true, message: '必须填写', trigger: 'blur' },
          { type: 'number', message: '必须为数字值', trigger: 'blur' },
        ],
      },

      // =============== 编辑表单 定义==============
      dialogEditVisible: false,
      formEdit: {},
      formEditRules: {
        pid: [{ required: true, message: '上级菜单未选择', trigger: 'blur' }],
        type: [{ required: true, message: '菜单类型未选择', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入菜单名', trigger: 'blur' },
          {
            min: 1,
            max: 63,
            message: '长度在 1 到 63 个字符',
            trigger: 'blur',
          },
        ],
        path: [
          { required: true, message: '请输入路径', trigger: 'blur' },
          {
            min: 1,
            max: 128,
            message: '长度在 1 到 128 个字符',
            trigger: 'blur',
          },
        ],
        icon: [
          { required: true, message: '图标未填写', trigger: 'blur' },
          {
            min: 1,
            max: 128,
            message: '长度在 1 到 128 个字符',
            trigger: 'blur',
          },
        ],
        sort: [{ required: true, message: '必须填写', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.getMenuTreeList()
  },
  methods: {
    // ========== 获取列表系列 ==============
    async getMenuTreeList() {
      const res = await this.$http.get(this.PAGE.API.menuTreeList)
      if (res.code !== 0) return
      if (!res.data.tree) return this.$message.error('数据为空')

      this.menuTreeList = res.data.tree
    },

    async getMenuIndentList(id) {
      const res = await this.$http.get(this.PAGE.API.menuTreeIndentList, {
        params: {
          cur_menu_id: id,
        },
      })
      if (res.code !== 0) return

      this.menuSelectList = res.data.indent || []
      this.menuSelectList.unshift({ name: '顶级菜单', id: 0, indent: '' })
    },

    // ==================================== 添加表单 ======================================
    dialogFormAddShow() {
      this.getMenuIndentList(0)

      this.dialogAddVisible = true
    },

    dialogFormAddDo() {
      this.$refs.formAddRef.validate(async (valid) => {
        if (!valid) return

        const res = await this.$http.post(this.PAGE.API.add, this.formAdd)
        if (res.code !== 0) return

        this.getMenuTreeList()

        this.dialogAddVisible = false
        this.$message.success('添加成功')
      })
    },

    // 监听添加菜单对话框的关闭条件
    dialogAddClosed() {
      this.$refs.formAddRef.resetFields()
    },

    // ===================================== 修改表单 方法 ====================================
    dialogFormEditShow(rowInfo) {
      this.dialogEditVisible = true
      this.formEdit = rowInfo
      this.getMenuIndentList(rowInfo.id)
    },
    dialogFormEditDo() {
      this.$refs.formEditRef.validate(async (valid) => {
        if (!valid) return
        //去掉一个字段
        this.$delete(this.formEdit, 'children')
        const res = await this.$http.post(this.PAGE.API.mod, this.formEdit)

        if (res.code !== 0) return
        this.getMenuTreeList()

        this.dialogEditVisible = false
        this.$message.success('修改成功')
      })
    },

    // 监听修改对话框关闭事件
    dialogEditClosed() {
      this.$refs.formEditRef.resetFields()
    },

    // ===================================== 删除操作 方法 ====================================
    tableRowDel(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.get(this.PAGE.API.del, {
            params: { id: id },
          })
          if (res.code !== 0) return

          this.$message.success('删除成功')
          this.getMenuTreeList()
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },

    // ===================================== 菜单排序 方法 ====================================
    async tableRowSortChange(row) {
      //这里需要转一次 不然 post提交 不过去参数
      const tmpRow = row
      const res = await this.$http.post(this.PAGE.API.mod, tmpRow)
      if (res.code !== 0) return

      this.getMenuTreeList()
      this.$message.success('排序成功')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.menu_root {
  @include scroll-x-hidden-y-auto;
}
</style>