<template>
  <div class="admin_root">
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 菜单搜索区域 -->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :span="2">
          <el-button type="primary" size="small" @click="dialogFormAddShow()">添加{{ PAGE.name }}</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="danger" size="small" @click="backupDb()">备份数据库</el-button>
        </el-col>
      </el-row>

      <!-- 表格区域区域 -->
      <el-table :data="tableDataList" style="width: 100%" border stripe>
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="nickname" label="昵称"> </el-table-column>
        <el-table-column prop="role" label="角色"> </el-table-column>
        <el-table-column prop="status" label="状态" width="70px">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 10">启用</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status === 20">禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="last_login_time" label="最后一次登录时间"> </el-table-column>
        <el-table-column prop="last_login_ip" label="最后一次登录IP"> </el-table-column>
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="dialogFormEditShow(scope.row)">编辑
            </el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="tableRowDel(scope.row.id)">删除
            </el-button>
            <el-button type="warn" icon="el-icon-edit" size="mini" @click="dialogFormEditPwdShow(scope.row)">密码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper" :total="tableDataTotal">
      </el-pagination>
    </el-card>

    <!-- 添加对话框 -->
    <el-dialog :title="'添加' + PAGE.name" :visible.sync="dialogAddVisible" width="50%" :close-on-click-modal="false"
      @close="dialogAddClosed">
      <span>
        <el-form :model="formAdd" :rules="formAddRules" ref="formAddRef" label-width="80px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="formAdd.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="formAdd.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="密码确认" prop="repassword">
            <el-input v-model="formAdd.repassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="启用状态">
            <el-switch v-model="formAdd.status" :active-value="10" :inactive-value="20"></el-switch>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAddVisible = false">取 消</el-button>
        <el-button size="small" @click="dialogFormAddDo()" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改对话框 -->
    <el-dialog :title="'修改' + PAGE.name" :visible.sync="dialogEditVisible" width="50%" :close-on-click-modal="false"
      @close="dialogEditClosed">
      <span>
        <el-form :model="formEdit" :rules="formEditRules" ref="formEditRef" label-width="80px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="formEdit.username"></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="formEdit.nickname"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-switch v-model="formEdit.status" :active-value="10" :inactive-value="20"></el-switch>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEditVisible = false">取 消</el-button>
        <el-button size="small" @click="dialogFormEditDo()" type="primary">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码对话框 -->
    <el-dialog :title="'修改' + PAGE.name + '密码'" :visible.sync="dialogEditPwdVisible" width="50%"
      :close-on-click-modal="false" @close="dialogEditPwdClosed">
      <span>
        <el-form :model="formEditPwd" :rules="formEditPwdRules" ref="formEditPwdRef" label-width="80px">
          <el-form-item label="密码" prop="password">
            <el-input v-model="formEditPwd.password" type="password" auto-complete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="密码确认" prop="repassword">
            <el-input v-model="formEditPwd.repassword" type="password" auto-complete="new-password"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEditPwdVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="dialogFormEditPwdDo()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // ========================== 页面信息 相关 ==========================
      PAGE: {
        name: '账号',
        API: {
          list: 'admin/list',
          add: 'admin/add',
          mod: 'admin/mod',
          del: 'admin/del',
          modPwd: 'admin/modPwd',
          taskDb: 'task/backup_db',
        },
      },

      // ========================== 表格 分页相关 ==========================
      tableDataList: [],
      tableDataTotal: 0,
      queryInfo: {
        page: 1,
        page_size: 10,
      },

      // ========================== 添加表单 定义 ==========================
      dialogAddVisible: false,
      formAdd: {
        username: '',
        password: '',
        repassword: '',
        status: 10,
      },
      formAddRules: {
        username: [
          { required: true, message: '用户名必须', trigger: 'blur' },
          { min: 3, message: '长度必须大于3位' },
        ],
        password: [
          { required: true, message: '密码必须', trigger: 'blur' },
          { min: 6, message: '输入密码长度必须大于6位' },
        ],
        repassword: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.formAdd.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
          },
        ],
      },

      // ========================== 编辑表单 定义 ==========================
      dialogEditVisible: false,
      formEdit: {},
      formEditRules: {
        username: [
          { required: true, message: '用户名必须', trigger: 'blur' },
          { min: 3, message: '长度必须大于3位' },
        ],
        status: [{ required: true, message: '状态必须', trigger: 'blur' }],
      },

      // ========================== 修改密码表单 定义 ==========================
      dialogEditPwdVisible: false,
      formEditPwd: {},
      formEditPwdRules: {
        password: [
          { required: true, message: '密码必须', trigger: 'blur' },
          { min: 6, message: '输入密码长度必须大于6位' },
        ],
        repassword: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.formEditPwd.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
          },
        ],
      },
    }
  },
  created() {
    this.getTableDataList()
  },
  methods: {
    // ==================================== 获取列表系列 ====================================
    async getTableDataList() {
      const res = await this.$http.get(this.PAGE.API.list, { params: this.queryInfo })

      if (res.code !== 0) return
      if (!res.data.list) return this.$message.error('数据为空')

      this.tableDataList = res.data.list
      this.tableDataTotal = res.data.total
    },

    // 监听 size 改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.page_size = newSize
      this.getTableDataList()
    },
    // 监听页码值 改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getTableDataList()
    },

    // ==================================== 添加表单 ======================================
    dialogFormAddShow() {
      this.dialogAddVisible = true
    },

    dialogFormAddDo() {
      this.$refs.formAddRef.validate(async (valid) => {
        if (!valid) return

        const res = await this.$http.post(this.PAGE.API.add, this.formAdd)
        if (res.code !== 0) return

        this.getTableDataList()

        this.dialogAddVisible = false
        this.$message.success('添加成功')
      })
    },

    async backupDb() {
      const res = await this.$httpPicRepo.get(this.PAGE.API.taskDb)

      if (res.code !== 0) return

      this.$message.success('开启备份博客数据任务...')
    },

    // 监听添加菜单对话框的关闭条件
    dialogAddClosed() {
      this.$refs.formAddRef.resetFields()
      this.imagePreviewUrl = ''
      this.formAdd.logo = ''
    },

    // ===================================== 修改表单 ====================================
    dialogFormEditShow(rowInfo) {
      this.dialogEditVisible = true
      this.formEdit = rowInfo
    },
    dialogFormEditDo() {
      this.$refs.formEditRef.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.post(this.PAGE.API.mod, this.formEdit)

        if (res.code !== 0) return

        this.dialogEditVisible = false
        this.$message.success('修改成功')
        this.getTableDataList()
      })
    },

    // 监听修改对话框关闭事件
    dialogEditClosed() {
      this.$refs.formEditRef.resetFields()
    },

    // ===================================== 修改密码表单 ====================================
    dialogFormEditPwdShow(rowInfo) {
      this.dialogEditPwdVisible = true
      this.formEditPwd = rowInfo
    },
    dialogFormEditPwdDo() {
      this.$refs.formEditPwdRef.validate(async (valid) => {
        if (!valid) return

        const res = await this.$http.post(this.PAGE.API.modPwd, {
          id: this.formEditPwd.id,
          password: this.formEditPwd.password,
        })

        if (res.code !== 0) return

        this.dialogEditPwdVisible = false
        this.$message.success('修改成功')
        this.getTableDataList()
      })
    },

    // 监听修改对话框关闭事件
    dialogEditPwdClosed() {
      this.$refs.formEditPwdRef.resetFields()
    },

    // ===================================== 删除操作 方法 ====================================
    tableRowDel(itemId) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await this.$http.get(this.PAGE.API.del, {
            params: { id: itemId },
          })

          if (res.code !== 0) return

          this.getTableDataList()
          return this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.admin_root {
  @include scroll-x-hidden-y-auto;
}
</style>
