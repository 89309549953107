<template>
  <el-container>
    <blog-menu @menuItemClick="menuItemClick" :currentNodeKey="formData.cat_id"></blog-menu>
    <!-- 右侧内容主体 -->
    <el-main class="blog_detail_wrap">
      <el-card class="blog_detail">
        <el-form label-width="80px" :model="formData" :rules="formDataRules" ref="formDataRef">
          <el-form-item label="标题" prop="title">
            <el-input v-model="formData.title" @blur="convertChTxt"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="4">
              <el-form-item :inline="true" label="分类" prop="cat_id">
                <el-select v-model="formData.cat_id" placeholder="请选择">
                  <el-option v-for="item in catList" :key="item.id" :label="item.indent + item.name"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="关键字" prop="route_key"> <el-input v-model="formData.route_key"
                  placeholder="用于快速检索"></el-input> </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-form-item :inline="true" label="显示" prop="show">
                <el-switch v-model="formData.show" active-color="#13ce66" inactive-color="#ff4949" :active-value="10"
                  :inactive-value="20"> </el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-form-item>
                <el-button type="primary" size="small" @click="blogSave()">{{ formData.id ? '修 改' : '发布' }}</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="内容" prop="content">
            <mavon-editor style="height: 700px" ref="md" v-model="formData.content" codeStyle="androidstudio"
              previewBackground="#C7EDCC" fontSize="13px" :tabSize="2" :navigation="false" :ishljs="true"
              :toolbars="toolbars" @imgAdd="blogImgAdd" @imgDel="blogImgDel" @save="blogMavonSave">
              <!-- 通过插槽将我们的icon插入到工具栏中 下面给出四个插槽名，大家可以自行更换-->
              <!-- left-toolbar-before -->
              <!-- left-toolbar-after -->
              <!-- right-toolbar-before -->
              <!-- right-toolbar-after -->
              <template v-slot:left-toolbar-after>
                <button type="button" title="加入参考文档" class="op-icon fa markdown-upload iconfont iconupload"
                  aria-hidden="true" @click="dialogReferDocVisible = true">
                  <!-- 这里用的是element-ui给出的图标 -->
                  <i class="el-icon-s-promotion" />
                </button>
              </template>
            </mavon-editor>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="blogSave()">{{ formData.id ? '修 改' : '发布' }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-main>

    <!-- 插入视频链接的dialog提示框，表单对话框 -->
    <el-dialog title="添加参考文献" :visible.sync="dialogReferDocVisible" :close-on-click-modal="false"
      @close="dialogReferDocClosed">
      <el-form :model="referDocForm">
        <el-form-item label="文献标题" :label-width="formLabelWidth">
          <el-input v-model="referDocForm.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="文献链接" :label-width="formLabelWidth">
          <el-input v-model="referDocForm.link" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogReferDocVisible = false">取 消</el-button>
        <el-button type="primary" @click="addReferDoc">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 浮动菜单按钮 -->
    <fav-btn class="fav_fix_top" target=".blog_detail_wrap" :visibility-height="10" :bottom="160" @click="blogSave()">
      <!-- 修改 按钮 -->
      <el-tooltip v-if="formData.id" effect="dark" content="修改-快捷方式" placement="bottom">
        <i class="fav_icon el-icon-edit-outline"></i>
      </el-tooltip>
      <!-- 发布 按钮-->
      <el-tooltip v-else effect="dark" content="发布-快捷方式" placement="bottom">
        <i class="fav_icon el-icon-thumb"></i>
      </el-tooltip>
    </fav-btn>
  </el-container>
</template>

<script>
// import global from '@/global/global.js'
import _ from 'lodash'

export default {
  data() {
    return {
      PAGE: {
        API: {
          add: 'blog/add',
          info: 'blog/info',
          mod: 'blog/mod',
          indent: 'blog_cat/indent',
          convert: 'blog/convert',
          uploadPic: 'upload/blog/pic',
        },
      },

      // ========================== 表单 定义 ==========================
      catList: [],
      formDataTmp: {},
      formData: {
        title: '',
        cat_id: undefined,
        show: 10,
        route_key: '',
      },
      formDataRules: {},
      // ========================== mavon-editor ==========================
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: true, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      dialogReferDocVisible: false,
      formLabelWidth: '130px',
      referDocForm: {},
    }
  },

  created() {
    this.getCatList()
    this.formDataTmp = _.cloneDeep(this.formData) // 拷贝一份

    this.formData.id = this.$route.query.id
    const sessionCatId = window.localStorage.getItem('blogCatSelectedId') * 1
    this.formData.cat_id = sessionCatId === 0 ? undefined : sessionCatId

    if (this.formData.id) {
      this.getBlogDetail(this.formData.id)
    }

    // console.log(this.formData)
    document.title = '记录生活瞬间啦^_^!'
  },

  watch: {
    // 监测路由变化
    $route(to, from) {
      // console.log('路由变化:', to, from)
      this.formData = this.formDataTmp
    },
  },

  methods: {
    // 转换中文为关键字
    async convertChTxt() {
      if (this.formData.title.length <= 0) return

      const res = await this.$http.get(this.PAGE.API.convert, { params: { title: this.formData.title } })

      if (res.code !== 0) return

      // 如果已经存在 route_key 则不在重新 赋值
      if (!this.formData.route_key) {
        this.formData.route_key = res.data.key
      }
    },

    // 获取分类列表
    async getCatList() {
      const res = await this.$http.get(this.PAGE.API.indent)

      if (res.code !== 0) return
      this.catList = res.data.indent
    },

    // 获取博客详情
    async getBlogDetail(id) {
      const res = await this.$http.get(this.PAGE.API.info, { params: { id: id } })

      if (res.code !== 0) return
      this.formData = res.data
    },

    // 左边博客分类
    menuItemClick(item) {
      this.$router.push({
        name: 'BlogList', // params传参：只能用 name
        params: {
          id: item.id,
        },
      })
    },

    // ========================== mavon-editor ==========================
    // 添加参考文献
    addReferDoc() {
      const tpl = `参考：\n- [${this.referDocForm.title}](${this.referDocForm.link})`
      this.formData.content = this.formData.content + tpl
      this.dialogReferDocVisible = false
      this.referDocForm = {}
    },
    // 关闭参考文献对话框
    dialogReferDocClosed() {
      this.referDocForm = {}
    },
    // 编辑器图片添加
    blogImgAdd(pos, $file) {
      const $vm = this.$refs.md
      // 第一步.将图片上传到服务器.
      const editorFormData = new FormData()
      editorFormData.append('business_type', 'blog')
      editorFormData.append('file', $file)

      this.$httpPicRepo({
        url: this.PAGE.API.uploadPic,
        method: 'post',
        data: editorFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((res) => {
        // $vm.$img2Url(pos, global.basePicRepoApiUrl + res.data.pic_url)
        $vm.$img2Url(pos, '/' + res.data.pic_url)
      })
    },
    blogImgDel(pos) {
      //console.log(pos)
    },
    blogMavonSave(value, render) {
      // console.log('this is render' + render)
      // console.log('this is value' + value)
      // console.log(this.$refs.md.d_render)
    },
    blogSave() {
      this.$refs.formDataRef.validate(async (valid) => {
        if (!valid) return

        let action_url = this.PAGE.API.add
        if (this.formData.id) {
          action_url = this.PAGE.API.mod
        }

        const res = await this.$http.post(action_url, this.formData)
        if (res.code !== 0) return

        this.$router.push({
          name: 'BlogDetail',
          query: {
            id: res.data.id,
          },
        })

        this.$message.success('操作成功')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.el-container {
  @include scroll-x-hidden-y-auto;
}

.el-main {
  padding: 0px 6px;

  .blog_detail {
    margin-bottom: 6px;

    .markdown-body {
      font-size: 13px;
    }
  }
}
</style>
