// 博客服务
let baseBlogApiUrl = '/blog-api/'
// 图片服务
let basePicRepoApiUrl = '/repo-api/'

// 如果是开发环境就替换 请求地址
if (process.env.NODE_ENV === 'development') {
  baseBlogApiUrl = 'http://127.0.0.1:8066/'
  basePicRepoApiUrl = 'http://192.168.66.205/'
}

// upload token
const picUploadHeaderObj = {
  Authorization: '',
}

export default {
  baseBlogApiUrl,
  basePicRepoApiUrl,
  picUploadHeaderObj,
}