<template>
  <div class="login_container">
    <div class="cover">
      <img class="bg" :src="bgUrl" alt="cover" />
    </div>
    <!-- 登录框 -->
    <div class="login_panel">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../../assets/img/logo.png" alt="" />
      </div>
      <!-- 登录表单区域 -->
      <el-form ref="loginFormRef" class="login_form" :model="loginForm" :rules="loginFormRules" @submit.native.prevent label-width="0px">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="iconfont icon-user" clearable></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" prefix-icon="iconfont icon-3702mima" type="password" clearable></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" size="small" native-type="submit" @click="login">登录</el-button>
          <el-button type="info" size="small" @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      PAGE: {
        API: {
          login: 'land/login',
        },
      },
      // 登录表单
      loginForm: {
        username: '',
        password: '',
        // username: 'admin',
        // password: 'qwe123',
      },
      // 登录规则校验
      loginFormRules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            min: 3,
            max: 10,
            message: '长度在 3 到 10 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '长度在 6 到 15 个字符',
            trigger: 'blur',
          },
        ],
      },
      // 背景
      bgUrl: '',
    }
  },
  created() {
    this.changeBg(0)
  },
  methods: {
    resetForm() {
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      // 注意加入了await 外围就需要加入 async 不然会报错
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return

        const res = await this.$http.post(this.PAGE.API.login, this.loginForm)
        if (res.code !== 0) return

        this.$message.success('登录成功')

        /** ========== 登录成功之后的操作 ========
        1.将登录成功之后的token, 保存到客户端的 localStorage 中
        2. 项目中除了登录之外的其他API接口,必须在登录之后才能访问
        3.token只因该在当前网站打开期间生效, 所以将 token 保存在 localStorage 中
         *** ===================================== */
        // 本地存储
        window.localStorage.setItem('blog_token', res.data.token)
        window.localStorage.setItem('blog_nickname', res.data.name)

        //2.通过编程式导航跳转到后台主页,路由地址是/home
        this.$router.push({ path: '/home' })
      })
    },
    // 更换背景
    changeBg(type) {
      if (type === 0) {
        // 默认壁纸
        this.bgUrl = `/images/background${Math.floor(Math.random() * 10 + 1)}.webp`
      } else if (type === 1) {
        // 每日一图
        this.bgUrl = 'https://api.dujin.org/bing/1920.php'
      } else if (type === 2) {
        // 随机风景
        this.bgUrl = 'https://api.ixiaowai.cn/gqapi/gqapi.php'
      } else if (type === 3) {
        // 随机动漫
        this.bgUrl = 'https://api.ixiaowai.cn/api/api.php'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/global-mixin.scss";

.login_container {
  background-color: #007acc;
  height: 100vh;
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.25s;

    .bg {
      transform: scale(1);
      filter: blur(0);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 1.5s ease 0s;
      backface-visibility: hidden;
    }
  }

  .login_panel {
    width: 450px;
    height: 300px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); //相对div元素本身为单位的偏移量

    .avatar_box {
      height: 100px;
      width: 100px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }

    .login_form {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .btns {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
