<template>
  <el-container class="home_container">
    <!-- 顶部区域 -->
    <el-header class="top_section" height="36px">

      <!-- 左边菜单 -->
      <div class="top_left">
        <!-- 系统名称 -->
        <div class="logo" @click="toHome"><img src="../assets/img/logo.png" /></div>
        <!-- 应用标题 -->
        <h1 class="app_name">{{ projectName }}</h1>
        <!-- 菜单 -->
        <el-menu class="menu_section" mode="horizontal" :unique-opened="true" :router="true"
          :default-active="activePath" text-color="#454c59" active-text-color="#409eff">
          <template v-for="item in menuList">

            <!-- 一级菜单 -->
            <el-submenu v-if="item.children && item.children.length" :index="item.id + ''" :key="item.id">
              <!-- 一级菜单的模板区域 -->
              <template slot="title">
                <span>{{ item.name }}</span>
              </template>
              <!-- 二级菜单 -->
              <el-menu-item :index="'/' + subItem.path" v-for="subItem in item.children" :key="subItem.id"
                @click="saveNavState('/' + subItem.path)">
                <template slot="title">
                  <span>{{ subItem.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <!-- 没有下级菜单 显示模式 -->
            <el-menu-item v-else :index="'/' + item.path" :key="item.id" @click="saveNavState('/' + item.path)">
              <span>{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>

      <!-- 中间部分 -->
      <div class="top_mid">
        <!-- 搜索输入框 -->
        <div class="search_panel" v-if="$route.path == '/blog/list'">
          <el-input placeholder="快速检索博文..." v-model="searchContent" size="mini" clearable> </el-input>
        </div>
        <div class="words_tips_wrap">
          <div class="words_tips">{{ wordsRandom }}</div>
        </div>
      </div>

      <!-- 右边菜单 -->
      <div class="top_right">
        <el-menu mode="horizontal" :unique-opened="true" :router="true" :default-active="activePath"
          text-color="#454c59" active-text-color="none">
          <el-menu-item index="/blog/write">
            <el-button type="danger" class="pub_blog" size="mini" color="#fff" round>发布博客</el-button>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-user-solid"></i> {{ currentUsername }} </template>
            <el-menu-item index="#" @click="dialogAccountSettingShow"><i class="el-icon-user"></i>账户设置</el-menu-item>
            <el-menu-item index="#" @click.native="logout"><i class="el-icon-s-promotion"></i>退出登录</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-header>

    <!-- 主体区域 -->
    <el-main class="main_section">
      <router-view :params="searchContent"></router-view>
    </el-main>

    <!-- 底部区域 -->
    <el-footer class="footer_section" height="36px">
      <div class="left">&nbsp;</div>
      <div class="right">粤ICP备19006658号 非盈利网站备案信息 ©2015-2023 @Simon个人博客</div>
    </el-footer>

    <!-- 修改密码对话框 -->
    <el-dialog :title="'修改密码'" :visible.sync="dialogAccountSettingVisible" width="50%" :close-on-click-modal="false"
      @close="dialogAccountSettingClosed">
      <span>
        <el-form :model="formAccountPwd" :rules="formAccountPwdRules" ref="formAccountPwdRef" label-width="80px">
          <el-form-item label="密码" prop="password">
            <el-input v-model="formAccountPwd.password" type="password" auto-complete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="重复" prop="repassword">
            <el-input v-model="formAccountPwd.repassword" type="password" auto-complete="new-password"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogAccountSettingVisible = false">取 消</el-button>
        <el-button size="small" @click="dialogAccountSettingDo" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      projectName: 'Simon博客',
      PAGE: {
        API: {
          logout: 'land/logout',
          modPwd: 'land/mod_pwd',
          menuTree: 'menu/tree',
          wordsRandom: 'words/random',
        },
      },

      menuList: [], //顶部菜单数据
      activePath: this.$route.path, //被选中的菜单
      searchContent: '',
      currentUsername: '默认用户',
      wordsRandom: '每日一句、随机',
      blogWriteRoute: { path: '/blog/write' },

      // ========================== 修改密码表单 定义 ==========================
      dialogAccountSettingVisible: false,
      formAccountPwd: {},
      formAccountPwdRules: {
        password: [
          { required: true, message: '密码必须', trigger: 'blur' },
          { min: 6, message: '输入密码长度必须大于6位' },
        ],
        repassword: [
          { required: true, message: '重复必须', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.formAccountPwd.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
          },
        ],
      },
    }
  },
  created() {
    this.getMenuList()
    this.getWords()

    this.activePath = window.localStorage.getItem('activePath') ? window.localStorage.getItem('activePath') : this.activePath
    this.currentUsername = window.localStorage.getItem('blog_nickname')
  },
  methods: {
    // 保存链接的激活状态
    saveNavState(activePath) {
      window.localStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },

    // 获取顶部的菜单
    async getMenuList() {
      const res = await this.$http.get(this.PAGE.API.menuTree)

      if (res.code !== 0) return

      if (!res.data.tree) return this.$message.error('数据为空')
      this.menuList = res.data.tree
    },

    // 获取每日一句
    async getWords() {
      const res = await this.$http.get(this.PAGE.API.wordsRandom)
      if (res.code !== 0) return
      this.wordsRandom = res.data.content
    },

    // 安全退出
    async logout() {
      const res = await this.$http.get(this.PAGE.API.logout)
      if (res.code !== 0) return

      window.localStorage.clear()
      this.$router.push('/login')
    },

    // 返回主页
    toHome() {
      document.title = this.projectName
      window.localStorage.setItem('blogCatSelectedId', 0)

      this.$router.push('/blog/list')
    },

    // ========== 账户设置 表单 ==========
    dialogAccountSettingShow() {
      this.dialogAccountSettingVisible = true
    },
    dialogAccountSettingDo() {
      this.$refs.formAccountPwdRef.validate(async (valid) => {
        if (!valid) return

        const res = await this.$http.post(this.PAGE.API.modPwd, {
          id: this.formAccountPwd.id,
          password: this.formAccountPwd.password,
        })

        if (res.code !== 0) return

        this.dialogAccountSettingVisible = false
        this.$message.success('修改成功')
      })
    },

    // 监听修改对话框关闭事件
    dialogAccountSettingClosed() {
      this.$refs.formAccountPwdRef.resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/global-mixin.scss";

.home_container {
  height: 100vh;
  // background-color: #666;

  // ============== 顶部区域 部分 ==============
  .top_section {
    @include flex-row;
    @include i-fixed;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    background-color: #fff;

    .top_left {
      @include flex-row-align-center;
      flex-shrink: 0;

      .logo {
        width: 26px;
        height: 26px;
        margin: 0 12px;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
        }

      }

      .app_name {
        font-size: 18px;
        padding-right: 16px;
        cursor: pointer;
      }

      .menu_section {
        flex-shrink: 0;
      }

    }

    .top_mid {
      flex: 1;
      @include flex-row-align-center;

      .search_panel {
        flex: 1;
        @include flex-row-align-center;
        margin-right: 6px;
      }

      .words_tips_wrap {
        flex: 1;
        overflow: hidden;
        /* 超出容器部分隐藏 */
        white-space: nowrap;
        /* 保持文本在一行，不换行 */
        text-overflow: ellipsis;
        /* 超出部分显示为省略号 */

        .words_tips {
          font-size: 12px;
          font-style: oblique;
          text-align: right;
        }
      }
    }

    .top_right {
      @include flex-row-align-center;
      flex-shrink: 0;

      .pub_blog {
        color: #fff;
      }
    }
  }

  // ============== 中间主体 部分 ==============
  .main_section {
    @include bg-linear-gradient;
    @include box-shadow;
    padding: 0px;
    margin-top: 38px;
    margin-bottom: 38px;
    overflow: hidden;

    .el-backtop {
      z-index: 1;
    }
  }

  // ============== 底部主体 部分 ==============
  .footer_section {
    @include flex-row-align-center;
    @include i-fixed;
    bottom: 0px;
    left: 0px;
    background-color: #eee;

    .left {
      flex: 1;
    }

    .right {
      margin-right: 12px;
    }
  }

}

// 去除菜单边框
.el-menu.el-menu--horizontal {
  border: none;
}

// 修改一级菜单高度
.el-menu--horizontal>.el-menu-item {
  height: 36px;
  line-height: 36px;
}

// 修改二级菜单高度
.el-menu--horizontal>.el-submenu /deep/ .el-submenu__title {
  height: 36px;
  line-height: 36px;
}
</style>
