import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login/Login.vue'
import HomeView from '../views/HomeView.vue'

import BlogList from '../views/Blog/BlogList.vue'
import BlogDetail from '../views/Blog/BlogDetail.vue'
import BlogWrite from '../views/Blog/BlogWrite.vue'
import BlogCat from '../views/BlogCat/BlogCat.vue'

import Words from '../views/Words/Words.vue'

import Menu from '../views/Menu/Menu.vue'
import Admin from '../views/Admin/Admin.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/login', component: Login, name: 'Login' },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    redirect: '/blog/list',
    children: [
      { path: '/blog/list', component: BlogList, name: 'BlogList' },
      { path: '/blog/write', component: BlogWrite, name: 'BlogWrite' },
      { path: '/blog', component: BlogDetail, name: 'BlogDetail' },
      { path: '/blog/cat', component: BlogCat, name: 'BlogCat' },

      { path: '/words', component: Words, name: 'Words' },

      { path: '/menu', component: Menu, name: 'Menu' },
      { path: '/admin', component: Admin, name: 'Admin' },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next() 放行 next('/login') 强制跳转

  // 如果用户访问的登录页,直接放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.localStorage.getItem('blog_token')
  // 没有token, 强制跳转到登录页
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

export default router
