/**
 * 重置message,防止重复点击重复弹出message弹框
 **/
import Vue from 'vue'
import { Message } from 'element-ui'

let messageInstance = null
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  messageInstance = Message(options)
}

;['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      }
    }
    options.type = type
    return resetMessage(options)
  }
})

export const Msg = resetMessage

/**
 * 注意：这里使用的$message,所以在使用时候也是this.$message
 **/
Vue.prototype.$message = function (msg) {
  return Msg({
    message: msg,
    duration: 3000,
    showClose: true,
  })
}

//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return Msg.success({
    message: msg,
    duration: 3000,
    showClose: true,
  })
}

Vue.prototype.$message.warning = function (msg) {
  return Msg.warning({
    message: msg,
    duration: 3000,
    showClose: true,
  })
}

Vue.prototype.$message.error = function (msg) {
  return Msg.error({
    message: msg,
    duration: 3000,
    showClose: true,
  })
}
