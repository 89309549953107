<template>
  <div class="blog_menu_wrap">
    <!-- 侧边栏 -->
    <el-tree ref="treeRef" node-key="id" :data="menuTreeList" :props="menuTreeOptions"
      :current-node-key="currentNodeKey" :default-expanded-keys="[currentNodeKey]" :highlight-current="true"
      @node-click="menuItemClick">
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'blog-menu',
  props: {
    // 选中节点 ID
    currentNodeKey: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    currentNodeKey(newVal) {
      // eslint-disable-next-line vue/no-mutating-props
      this.currentNodeKey = newVal
      this.$nextTick(() => {
        this.$refs.treeRef.setCurrentKey(newVal)
      })
    },
  },
  data() {
    return {
      PAGE: {
        API: {
          list: 'blog_cat/tree',
        },
      },
      // 分类菜单变量
      menuTreeList: [],
      menuTreeOptions: {
        children: 'children',
        label: 'name',
      },
    }
  },
  created() {
    this.getMenuTreeList()
  },
  methods: {
    async getMenuTreeList() {
      const res = await this.$http.get(this.PAGE.API.list)
      if (res.code !== 0) return

      this.menuTreeList = res.data.tree
      this.menuTreeList.unshift({ id: 0, name: '全部博文', pid: 0, sort: 1, show: 10, desc: 'default' })
    },
    menuItemClick(item) {
      document.title = item.name
      window.localStorage.setItem('blogCatSelectedId', item.id)
      this.$emit('menuItemClick', item)
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/css/global-mixin.scss";

.blog_menu_wrap {
  @include bg-glass;
  @include scroll-x-hidden-y-auto;
  width: 160px;
  height: 100%;
  flex-shrink: 0;

  .el-tree {
    width: 100%;
    background: none;
  }

  .el-tree-node.is-current>.el-tree-node__content {
    background-color: #2f8dfb !important;
    color: white;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
  }

  .el-tree-node__expand-icon {
    color: #000;
  }

  /* 改变颜色后，这个一定要设成透明 */
  .el-tree-node__expand-icon.is-leaf {
    color: transparent !important;
  }

}
</style>
